import React, { lazy, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validateToken } from 'shared/slices/userSlice';
import LoggedInContainer from 'shared/components/LoggedInContainer';
import './App.scss';
import Api from 'core/Api';
import { MessageReadItemContextProvider } from './read-panel/MessageReadItemContext';

const AnonymousUserView = lazy(() => import('./login/AnonymousUserView'));
const ComposePanel = lazy(() => import('./compose-panel/ComposePanel'));
const ReadPanel = lazy(() => import('./read-panel/ReadPanel'));

const App = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);

  // To avoid passing the Api token to every call, it is stored within Api
  // when the App mounts
  useEffect(() => {
    if (token) {
      Api.setToken(token);
    }
  }, [token]);

  const hasAttemptedTokenValidation = useSelector(
    (state) => state.user.hasAttemptedTokenValidation,
  );

  useEffect(() => {
    if (token && !hasAttemptedTokenValidation) {
      dispatch(validateToken(token));
    }
  }, [dispatch, hasAttemptedTokenValidation, token]);

  const getEntryView = () => {
    if (!token) {
      return <AnonymousUserView />;
    } else if (hasAttemptedTokenValidation) {
      // In order to make use of traditional client-side routing, we would need to
      // run this application in a server to handle redirecting requests to the same
      // URL that handles the routing. To avoid the overhead of provisioning and
      // maintaning a server and to help with the ease of deployment, we will
      // handle routing view query string parameters.
      const queryString = window.location.search || '';
      if (queryString.includes('view=read')) {
        return (
          <MessageReadItemContextProvider>
            <LoggedInContainer>
              <ReadPanel />
            </LoggedInContainer>
          </MessageReadItemContextProvider>
        );
      }
      // 'view=compose' or default view
      return (
        <LoggedInContainer>
          <ComposePanel />
        </LoggedInContainer>
      );
    }
    return <div className='loader' />;
  };

  return (
    <div className='app'>
      <Suspense fallback={<div className='loader' />}>
        {getEntryView()}
      </Suspense>
    </div>
  );
};

export default App;
