/* eslint-disable react/button-has-type */
// ^ Dynamic button types are not supported because of one surly developer :/
//   https://github.com/yannickcr/eslint-plugin-react/issues/1555
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './ButtonLink.scss';

const Sizes = {
  SMALL: 'small',
  MEDIUM: 'medium',
};

const ButtonLink = ({
  className,
  isDarkMode,
  isDisabled,
  label,
  onClick,
  size,
  type,
  ...props
}) => {
  const buttonLinkClassName = classnames(
    'ButtonLink',
    `ButtonLink--${size}`,
    className,
    {
      'ButtonLink--isDarkMode': isDarkMode,
      'ButtonLink--isDisabled': isDisabled,
    },
  );

  return (
    <button
      {...props}
      className={buttonLinkClassName}
      disabled={isDisabled}
      onClick={onClick}
      type={type}
    >
      {label}
    </button>
  );
};

ButtonLink.defaultProps = {
  className: null,
  isDarkMode: false,
  isDisabled: false,
  size: Sizes.MEDIUM,
  type: 'button',
};

ButtonLink.propTypes = {
  /** An optional class name to render on the root node */
  className: PropTypes.string,
  /** Whether the button is rendering on a dark background */
  isDarkMode: PropTypes.bool,
  /** Whether the button should render in a disabled state */
  isDisabled: PropTypes.bool,
  /** The button text */
  label: PropTypes.node.isRequired,
  /** Function to call when clicked */
  onClick: PropTypes.func.isRequired,
  /** Size of the button text */
  size: PropTypes.oneOf(Object.values(Sizes)),
  /** The native HTML button type to render */
  type: PropTypes.oneOf(['button', 'submit']),
};

export default ButtonLink;
