import { createSlice } from '@reduxjs/toolkit';
import { getEmailTemplates } from 'shared/api';
import modifyDataForReactSelect from 'shared/utils/modifyDataForReactSelect';

const emailTemplatesInitialState = {
  emailTemplates: null,
  emailTemplatesLoading: false,
  emailTemplatesError: null,
};

const emailTemplatesSlice = createSlice({
  name: 'emailTemplates',
  initialState: emailTemplatesInitialState,
  reducers: {
    loadEmailTemplatesBegin: (state) => {
      state.emailTemplatesLoading = true;
      state.emailTemplatesError = null;
    },
    loadEmailTemplatesSuccess: (state, action) => {
      state.emailTemplatesLoading = false;
      state.emailTemplates = action.payload;
    },
    loadEmailTemplatesError: (state, action) => {
      state.emailTemplatesLoading = false;
      state.emailTemplatesError = action.payload;
    },
  },
});

const {
  loadEmailTemplatesBegin,
  loadEmailTemplatesError,
  loadEmailTemplatesSuccess,
} = emailTemplatesSlice.actions;

const loadEmailTemplates = (token) => (dispatch) => {
  dispatch(loadEmailTemplatesBegin());
  getEmailTemplates(token)
    .then((responseData) => modifyDataForReactSelect(responseData))
    .then((emailTemplates) => {
      dispatch(loadEmailTemplatesSuccess(emailTemplates));
    })
    .catch((error) => {
      dispatch(
        loadEmailTemplatesError(error.message || 'something went wrong'),
      );
    });
};

export { loadEmailTemplates };
export default emailTemplatesSlice.reducer;
