import { combineReducers, configureStore } from '@reduxjs/toolkit';
import emailTemplatesReducer from 'shared/slices/emailTemplatesSlice';
import logUserActivityReducer from 'shared/slices/logUserActivitySlice';
import searchesReducer from 'shared/slices/searchesSlice';
import userReducer from 'shared/slices/userSlice';

const preloadedState = {
  user: {
    token: localStorage.getItem('outlookToken'),
  },
};

const store = configureStore({
  reducer: combineReducers({
    emailTemplates: emailTemplatesReducer,
    logUserActivity: logUserActivityReducer,
    searches: searchesReducer,
    user: userReducer,
  }),
  preloadedState: preloadedState,
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
