import { createSlice } from '@reduxjs/toolkit';

const logUserActivityInitialState = {
  selectedSearch: null,
  shouldLogUserActivityInTRM: true,
  selectedEmailTemplate: null,
};

const logUserActivitySlice = createSlice({
  name: 'logUserActivity',
  initialState: logUserActivityInitialState,
  reducers: {
    selectSearch: (state, action) => {
      state.selectedSearch = action.payload;
    },
    startLoggingOutreaches: (state) => {
      state.shouldLogUserActivityInTRM = true;
    },
    stopLoggingOutreaches: (state) => {
      state.shouldLogUserActivityInTRM = false;
      state.selectedSearch = null;
    },
    selectEmailTemplate: (state, action) => {
      state.selectedEmailTemplate = action.payload;
    },
  },
});

export const {
  selectEmailTemplate,
  selectSearch,
  startLoggingOutreaches,
  stopLoggingOutreaches,
} = logUserActivitySlice.actions;

export default logUserActivitySlice.reducer;
