/* eslint-disable import/prefer-default-export */

const THRIVE_TRM_LOCAL_PORT = 3002;

const getApiUrlForTenant = (tenant) => {
  const [_subdomain, domain, tld] = window.location.hostname.split('.');
  const tenantApiUrl = `https://${tenant}.${domain}.${tld}`;

  return process.env.NODE_ENV === 'development'
    ? `${tenantApiUrl}:${THRIVE_TRM_LOCAL_PORT}`
    : tenantApiUrl;
};

const apiUrl = getApiUrlForTenant('api');

export { apiUrl, getApiUrlForTenant };
