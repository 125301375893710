import { apiUrl } from 'shared/utils/url';

const TOKEN_KEY = 'outlookToken';

const StatusCodes = {
  UNPROCESSABLE_ENTITY: 422,
};

const cacheToken = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

const invalidateCachedToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};

const getHeaders = (token) => ({
  Authorization: `Token token=${token}`,
});

const requestAuthToken = async (url, userEmail, password) => {
  try {
    const data = {
      authentication: {
        email: userEmail,
        password: password,
        attributes: ['outreach_email_address'],
      },
    };
    const response = await fetch(`${url}/users/authenticate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      const error = {
        message: '',
      };
      if (response.status === StatusCodes.UNPROCESSABLE_ENTITY) {
        // Unauthorized credentials, should endpoint return 401?
        error.message = 'The credentials you provided are invalid.';
      }
      return Promise.reject(error);
    }
    const json = await response.json();
    return json;
  } catch (error) {
    throw new Error(
      'Is your Thrive URL is correct? If so, check your network connection.',
    );
  }
};

const requestUser = async (token) => {
  const url = `${apiUrl}/api/v1/me`;
  const response = await fetch(url, {
    method: 'GET',
    headers: getHeaders(token),
  });
  const data = await response.json();
  if (!response.ok) {
    throw new Error(data.error);
  }
  return data;
};

const getEmailTemplates = async (token) => {
  const url = `${apiUrl}/api/v1/email_templates`;
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: getHeaders(token),
    });
    const json = await response.json();
    const templates = await json.email_templates;
    if (!response.ok) {
      throw new Error(json.error);
    }
    return templates;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getSearches = async (token) => {
  const url = `${apiUrl}/api/v1/searches`;
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Token token=${token}`,
      },
    });
    const json = await response.json();
    const searches = await json.searches;
    if (!response.ok) {
      throw new Error(json.error);
    }
    return searches;
  } catch (error) {
    throw new Error(error.message);
  }
};

export {
  cacheToken,
  getEmailTemplates,
  getSearches,
  invalidateCachedToken,
  requestAuthToken,
  requestUser,
};
