import React, { useState, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';

const CURRENT_USER_RECIPIENT_TYPE = 'user';

// A ReadPanel specific context that stores Office.context.mailbox.item data via the Office.MessageRead interface.
// https://docs.microsoft.com/en-us/javascript/api/outlook/office.messageread?view=outlook-js-preview
const MessageReadItemContext = createContext();

const MessageReadItemContextProvider = ({ children }) => {
  const [messageContext, setMessageContext] = useState(null);

  const { Office } = window;
  const { item, userProfile } = Office.context.mailbox;

  /* The Office.js API model uses a local Javascript proxy object as placeholder for the body property value. 
  ```js
  Office.mailbox.item.body => { 
    getAsync: function(type, body => ... ) {...} 
  }
  ```
  As the ReadPanel `item` represents a previously sent message with static values,
  all the used values are resolved once when the panel opens. 

  - More on Office.js proxy objects: https://buildingofficeaddins.com/proxy-objects/
  - More on JS proxy objects: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy
  */
  useEffect(() => {
    const isInboundMessage =
      item.from.recipientType !== CURRENT_USER_RECIPIENT_TYPE;

    item.body.getAsync(Office.CoercionType.Html, (body) => {
      setMessageContext({
        message: body.value,
        subject: item.normalizedSubject,
        internetMessageId: item.internetMessageId,
        conversationId: item.conversationId,
        isReply: Boolean(isInboundMessage),
        contactedOn: item?.dateTimeCreated.toString(),
        contactEmails: [item.from, ...item.to]
          .filter(
            (recipient) =>
              recipient.recipientType !== CURRENT_USER_RECIPIENT_TYPE,
          )
          .map((recipient) => recipient.emailAddress)
          .filter((emailAddress) => emailAddress !== userProfile?.emailAddress),
      });
    });
  }, [Office.CoercionType.Html, item, userProfile]);

  return messageContext ? (
    <MessageReadItemContext.Provider value={messageContext}>
      {children}
    </MessageReadItemContext.Provider>
  ) : (
    <div className='loader' />
  );
};

MessageReadItemContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MessageReadItemContext;

export { MessageReadItemContextProvider };
