import { createSlice } from '@reduxjs/toolkit';
import {
  cacheToken,
  invalidateCachedToken,
  requestAuthToken,
  requestUser,
} from 'shared/api';

export const userInitialState = {
  baseBccEmail: null,
  loginError: null,
  isLoginInProgress: false,
  token: null,
  hasAttemptedTokenValidation: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState: userInitialState,
  reducers: {
    loginBegin: (state) => {
      state.loginError = null;
      state.isLoginInProgress = true;
    },
    loginSuccess: (state, action) => {
      const { baseBccEmail, token } = action.payload;
      state.baseBccEmail = baseBccEmail;
      state.hasAttemptedTokenValidation = true;
      state.loginError = null;
      state.isLoginInProgress = false;
      state.token = token;
    },
    loginError: (state, action) => {
      state.baseBccEmail = null;
      state.hasAttemptedTokenValidation = true;
      state.loginError = action.payload;
      state.isLoginInProgress = false;
      state.token = null;
    },
    logout: (state) => {
      state.baseBccEmail = null;
      state.hasAttemptedTokenValidation = false;
      state.loginError = null;
      state.isLoginInProgress = false;
      state.token = null;
      invalidateCachedToken();
    },
    resetLoginError: (state) => {
      state.loginError = null;
    },
  },
});

export const { loginBegin, loginError, loginSuccess, logout, resetLoginError } =
  userSlice.actions;

export const login = (tenantUrl, email, password) => (dispatch) => {
  dispatch(loginBegin());
  requestAuthToken(tenantUrl, email, password)
    .then((response) => {
      dispatch(
        loginSuccess({
          token: response.access_token,
          baseBccEmail: response.attributes.outreach_email_address,
        }),
      );
      cacheToken(response.access_token);
    })
    .catch((error) => {
      dispatch(loginError(error.message));
      invalidateCachedToken();
    });
};

const validateToken = (token) => (dispatch) => {
  dispatch(loginBegin());
  requestUser(token)
    .then((response) => {
      dispatch(
        loginSuccess({
          token: token,
          baseBccEmail: response.outreach_email_address,
        }),
      );
      cacheToken(token);
    })
    .catch((error) => {
      dispatch(loginError(error.message));
      invalidateCachedToken();
    });
};

export { validateToken };
export default userSlice.reducer;
