import { createSlice } from '@reduxjs/toolkit';
import { getSearches } from 'shared/api';
import modifyDataForReactSelect from 'shared/utils/modifyDataForReactSelect';

const searchesInitialState = {
  searches: null,
  searchesLoading: false,
  searchesError: null,
};

const searchesSlice = createSlice({
  name: 'searchesSlice',
  initialState: searchesInitialState,
  reducers: {
    loadSearchesBegin: (state) => {
      state.searchesLoading = true;
      state.searchesError = null;
    },
    loadSearchesSuccess: (state, action) => {
      state.searchesLoading = false;
      state.searches = action.payload;
    },
    loadSearchesError: (state, action) => {
      state.searchesLoading = false;
      state.searchesError = action.payload;
    },
  },
});

const { loadSearchesBegin, loadSearchesError, loadSearchesSuccess } =
  searchesSlice.actions;

const loadSearches = (token) => (dispatch) => {
  dispatch(loadSearchesBegin());
  getSearches(token)
    .then((searches) => modifyDataForReactSelect(searches))
    .then((searches) => {
      dispatch(loadSearchesSuccess(searches));
    })
    .catch((error) => {
      dispatch(loadSearchesError(error.message || 'something went wrong'));
    });
};

export { loadSearches };
export default searchesSlice.reducer;
